<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-icon="mdi-magnify"
          @input="fetchProducts"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openCreateDialog">
          Create Product
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="products"
        :search="search"
        :loading="loading"
        :hide-default-footer="true"
      >
        <template v-slot:item.price="{ item }">
          <h3 color="primary">R {{ item.price ? item.price : "0" }}</h3>
        </template>
        <template v-slot:item.name="{ item }">
          <strong>{{ item.insurerName }}</strong> - {{ item.name }}
        </template>
        <template v-slot:item.commissionSplit="{ item }">
          <v-btn color="blue" small @click="viewCommissionSplit(item)">
            {{
              item.commissionSplit
                ? "View Commission Split"
                : "No Commission Data"
            }}
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-4" small @click="editProduct(item)" color="primary">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteProduct(item)" color="error">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <p>
            <span color="primary">{{ item.createdAt | getFormattedDate }}</span>
          </p>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="fetchProducts"
        :total-visible="7"
      ></v-pagination>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >{{ isEdit ? "Edit" : "Create" }} Product</span
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <p>Fill in the information about the insurance product</p>

              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="formData.name"
                    label="Name"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Product Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="formData.description"
                    label="Description"
                    required
                    counter="50"
                    outlined
                    :rules="[(v) => !!v || 'Product Description is required']"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <base-insurer-picker
                    :chosen-insurer.sync="formData.insurer"
                    :initial="formData.insurer"
                    label="Insurer"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Insurer is required']"
                  />
                </v-col>
                <v-col md="6">
                  <base-product-category-picker
                    :chosen-product-category.sync="formData.category"
                    :initial="formData.category"
                    label="Product Category"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Product Category is required']"
                  />
                </v-col>
                <v-col col="12">
                  <p>Price of product</p>
                  <v-text-field
                    v-model="formData.price"
                    label="Price"
                    outlined
                    prefix="R "
                    maxlength="4"
                    type="Number"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <p>Commission Split</p>
                <v-row>
                  <v-col col="4">
                    <v-text-field
                      v-model="formData.commissionSplit[0].agentSplit"
                      label="Agent Split"
                      outlined
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col col="4">
                    <v-text-field
                      v-model="formData.commissionSplit[1].companySplit"
                      label="Company Split"
                      outlined
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col col="4">
                    <v-text-field
                      v-model="formData.commissionSplit[2].retainerFund"
                      label="Retainer Fund"
                      outlined
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveProduct">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="commissionDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Commission Split Details</span>
          </v-card-title>
          <v-card-text>
            <div v-if="Object.keys(selectedCommissionSplit).length === 0">
              <p>No commission split yet</p>
            </div>
            <div v-else>
              <table class="commission-table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>%</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, key) in selectedCommissionSplit"
                    :key="key"
                  >
                    <td>{{ formatKey(key) }}</td>
                    <td>{{ value }}</td>
                    <td>R {{ calculateValueInRands(value) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="commissionDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import NewProductPicker from "../../../components/Base/Pickers/NewProductPicker.vue";
export default {
  name: "ViewProducts",
  components: {
    NewProductPicker,
  },
  data() {
    return {
      search: "",
      options: {},
      commissionDialog: false,
      selectedCommissionSplit: {},
      selectedPrice: 0, // Holds the price of the selected product
      headers: [
        { text: "Name", value: "name" },
        // { text: "Description", value: "description" },
        { text: "Category", value: "category" },
        { text: "price", value: "price" },
        { text: "Commission Split", value: "commissionSplit" },
        // { text: "Date", value: "createdAt" },
        { text: "Actions", align: "right", sortable: false, value: "actions" },
      ],
      products: [],

      totalRecords: 0,
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
      loading: false,
      dialog: false,
      isEdit: false,
      formData: {
        id: "",
        name: "",
        description: "",
        category: "",
        insurer: "",
        price: 0,
        createdBy: "",
        commissionSplit: [
          { agentSplit: 0 },
          { companySplit: 0 },
          { retainerFund: 0 },
        ],
      },
      valid: false,
      rules: {
        required: (value) => !!value || "Required.",
        number: (value) => !isNaN(value) || "Must be a number.",
      },
    };
  },
  methods: {
    async fetchCurrentUserId() {
      const ME_QUERY = gql`
        query Me {
          me {
            id
          }
        }
      `;

      try {
        const { data } = await this.$apollo.query({ query: ME_QUERY });
        this.userId = data.me.id;
        return this.userId;
      } catch (error) {
        console.error("Error fetching current user ID:", error);
        throw new Error("Unable to fetch user ID.");
      }
    },
    viewCommissionSplit(item) {
      // Ensure commissionSplit is an array
      if (!Array.isArray(item.commissionSplit)) {
        this.selectedCommissionSplit = {}; // Clear existing data
        this.selectedPrice = 0; // Default price to 0
        this.commissionDialog = true;
        return;
      }

      // Flatten the array of objects into a single object for easier display
      this.selectedCommissionSplit = item.commissionSplit.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

      // Store the item's price for value calculations
      this.selectedPrice = item.price || 0;

      this.commissionDialog = true;
    },
    formatKey(key) {
      // Convert camelCase to "Camel Case"
      return key
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // Add space before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    },
    calculateValueInRands(percentValue) {
      // Convert percent to decimal and multiply by the price
      const price = parseFloat(this.selectedPrice) || 0;
      const percent = parseFloat(percentValue) || 0;
      return ((price * percent) / 100).toFixed(2); // Return value as a string with 2 decimal places
    },
    async fetchProducts() {
      this.loading = true;
      const skip = (this.page - 1) * this.itemsPerPage;
      let query = {};
      if (this.search.length > 1) {
        query = {
          $or: [
            { name: { $regex: this.search, $options: "i" } },
            { description: { $regex: this.search, $options: "i" } },
            { insurerName: { $regex: this.search, $options: "i" } },
            {
              price: isNaN(this.search) ? undefined : parseFloat(this.search),
            }, // Check if the search is a number for product search
          ].filter(
            (condition) =>
              condition.name !== undefined ||
              condition.description ||
              condition.insurerName ||
              condition.price
          ), // Filter out undefined conditions
        };
      }

      try {
        const response = await this.$apollo.query({
          query: gql`
            query Products($limit: Int!, $skip: Int!, $query: JSON!) {
              products(limit: $limit, skip: $skip, query: $query) {
                records {
                  id
                  name
                  description
                  category
                  insurer
                  insurerName
                  # featurePicture
                  price
                  commissionSplit
                  createdAt
                }
                count
              }
            }
          `,
          variables: {
            limit: this.itemsPerPage,
            skip,
            query,
          },
          fetchPolicy: "network-only",
        });

        // Assign the response data to component data
        this.products = response.data.products.records;
        this.totalRecords = response.data.products.count;
        this.pageCount = Math.ceil(this.totalRecords / this.itemsPerPage);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        this.loading = false;
      }
    },

    openCreateDialog() {
      this.isEdit = false;
      this.formData = {
        id: "",
        name: "",
        description: "",
        category: "",
        insurer: "",
        price: 0,
        commissionSplit: [
          { agentSplit: 0 },
          { companySplit: 0 },
          { retainerFund: 0 },
        ],
      };
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async saveProduct() {
      if (this.$refs.form.validate()) {
        const userId = await this.fetchCurrentUserId(); // Fetch current user ID
        try {
          const formattedProduct = {
            ...this.formData,
            price: parseInt(this.formData.price, 10),
          };

          const mutation = this.isEdit
            ? gql`
                mutation UpdateProduct($product: ProductUpdateInput!) {
                  updateProduct(product: $product) {
                    id
                    updated
                  }
                }
              `
            : gql`
                mutation CreateProduct($product: ProductCreateInput!) {
                  createProduct(product: $product) {
                    id
                  }
                }
              `;

          const variables = this.isEdit
            ? {
                product: {
                  ...formattedProduct,
                  id: formattedProduct.id, // Include ID for update
                  createdBy: undefined, // Not needed for update
                  updatedBy: userId, // Set updatedBy
                  insurerName: undefined, // Remove extraneous fields
                  createdAt: undefined,
                  __typename: undefined,
                },
              }
            : {
                product: {
                  ...formattedProduct,
                  id: undefined, // Remove ID for creation
                  updatedBy: undefined, // Not needed for creation
                  createdBy: userId, // Add createdBy for creation
                },
              };

          // Clean up extraneous fields for both cases
          if (!this.isEdit) {
            delete variables.product.id;
          }
          delete variables.product.__typename;

          await this.$apollo.mutate({
            mutation,
            variables,
          });

          // Display a success message using SweetAlert
          this.$swal({
            title: "Success",
            text: this.isEdit
              ? "Product has been updated successfully!"
              : "Product has been added successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.formData = {
              id: "",
              name: "",
              description: "",
              category: "",
              insurer: "",
              price: 0,
              commissionSplit: [
                { agentSplit: 0 },
                { companySplit: 0 },
                { retainerFund: 0 },
              ],
            };
            this.fetchProducts();
          });

          this.dialog = false; // Close the dialog
        } catch (error) {
          console.error("Error saving product:", error);
        }
      }
    },

    editProduct(item) {
      this.isEdit = true;
      this.formData = { ...item };

      // Ensure `commissionSplit` is initialized correctly
      if (
        !this.formData.commissionSplit ||
        this.formData.commissionSplit.length < 3
      ) {
        this.formData.commissionSplit = [
          { agentSplit: 0 },
          { companySplit: 0 },
          { retainerFund: 0 },
        ];
      }

      this.dialog = true;
    },
    addCommissionSplit() {
      this.formData.commissionSplit.push({
        agentSplit: 0,
        companySplit: 0,
        retainerFund: 0,
      });
    },
    removeCommissionSplit(index) {
      this.formData.commissionSplit.splice(index, 1);
    },

    async deleteProduct(item) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation DeleteProduct($id: ID!) {
              deleteProduct(id: $id) {
                id
                deleted
              }
            }
          `,
          variables: { id: item.id },
        });
        this.$swal({
          title: "Information",
          text: "Product has been successfully deleted!",
          icon: "info",
          confirmButtonText: "OK",
        }).then(() => {
          this.fetchProducts();
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

  created() {
    this.fetchProducts();
  },
};
</script>

<style scoped>
.v-data-table {
  min-height: 400px;
}

.commission-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border: 1px solid #ddd;
}

.commission-table th,
.commission-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.commission-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
</style>
